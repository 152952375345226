<template>
	<div class="roleCheck">
		<div class="titleTxt">基础资料</div>
		<div class="column-me">
			<div class="row-me row-center margin-bottom30">
				<div class="left-title margin-right10">手机号</div>
				<div class="">
					<el-input placeholder="请输入" style="width: 100%;" clearable v-model="user.mobile"
						:disabled="true"></el-input>
				</div>
			</div>
			<div class="row-me row-center margin-bottom30">
				<div class="left-title margin-right10">公司名称</div>
				<div class="width220">
					<!-- <el-select placeholder="公司名称" style="width: 100%;" filterable clearable v-model="form.companyName">
						<el-option v-for="item in roleArray" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select> -->
					<el-input placeholder="请输入" style="width: 100%;" clearable v-model="form.name"></el-input>
				</div>
			</div>
			<div class="row-me row-center margin-bottom30">
				<div class="left-title margin-right10">行业</div>
				<div class="width220">
					<el-select placeholder="行业" style="width: 100%;" filterable clearable v-model="form.trade_id">
						<el-option v-for="item in roleArray" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</div>
			</div>
			<div class="row-me row-center margin-bottom30">
				<div class="left-title margin-right10">联系人</div>
				<div class="">
					<el-input placeholder="请输入" style="width: 100%;" clearable v-model="form.contacts"></el-input>
				</div>
			</div>
			<div class="row-me row-center margin-bottom30">
				<div class="left-title margin-right10">邮箱</div>
				<div class="">
					<el-input placeholder="请输入" style="width: 100%;" clearable v-model="form.email"></el-input>
				</div>
			</div>
			<div class="row-me row-center margin-bottom30">
				<div class="left-title margin-right10">地址</div>
				<div class="flex1">
					<el-input placeholder="请输入" style="width: 100%;" clearable v-model="form.address"></el-input>
				</div>
			</div>
			<div class="row-me row-center margin-top20">
				<div class="btn1 pointer" @click="modifyMobile">修改手机号</div>
				<div class="btn2 pointer" @click="editorialClick">保存编辑资料</div>
			</div>
		</div>

		<el-dialog v-model="dialogUser" v-if="dialogUser" title="修改手机号" class="phoneBox" width="27.7%">
			<div class="font-size16 telBox margin-bottom30 align-center">
				原手机号：{{user.mobile}}
			</div>
			<div class="newTel row-me row-center margin-bottom30">
				<img src="../../assets/img/icon_sj.png" alt="" class="icon_sjImg" />
				<el-input v-model="form1.mobile" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入新手机号"
					maxlength="11" />
			</div>
			<div class="newTel row-me row-center margin-bottom100">
				<img src="../../assets/img/icon_yzm.png" alt="" class="icon_sjImg" />
				<el-input v-model="form1.code" placeholder="请输入验证码" maxlength="8" />
				<div class="codeBox pointer">
					<span v-show="show" @click="getCode" class="getCode">点击发送验证码</span>
					<span v-show="!show" class="count">{{count}}s后重新获取</span>
				</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogUser = false" class="width100height40">取消</el-button>
					<el-button type="primary" @click="ensureClick">
						确定
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				form1: {
					mobile: '',
					code: '',
				},
				form: {
					mobile: '',
				},
				roleArray: [],
				dialogUser: false,
				show: true,
				count: '',
				timer: null,
				editorial: true,
				user: {},
			}
		},
		// 侦听器
		watch: {},
		// 计算属性
		computed: {},
		// html加载完成之前
		created() {
			this.sysTradeWay()
		},
		// html加载完成后执行。
		mounted() {

		},
		// 事件方法执行
		methods: {
			sysTradeWay() {
				this.$post("index/sysTradeList", {}).then((res) => {
					if (res) {
						this.roleArray = res.data;
						this.getData();
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			userInfoDetailsWay() {
				this.$post("index/userInfoDetails", {
					id: sessionStorage.getItem('userId')
				}).then((res) => {
					if (res) {
						this.roleArray = res.data;
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			//获取数据
			getData() {
				this.$post("index/companyInfoDetails", {
					id: sessionStorage.getItem('userId')
				}).then((res) => {
					if (res) {
						this.user = res.data;
						this.form = res.data;
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			// 编辑资料
			editorialClick() {
				if (this.form.name == '') {
					this.$message.error("公司名称不能为空");
					return false;
				}
				if (this.form.trade_id == '') {
					this.$message.error("行业不能为空");
					return false;
				}
				if (this.form.contacts == '') {
					this.$message.error("联系人不能为空");
					return false;
				}
				if (this.form.email == '') {
					this.$message.error("邮箱不能为空");
					return false;
				}

				let br = false;
				if (/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(this.form.email)) {
					br = true;
				}
				if (br == false) {
					this.$message.error("请输入正确的邮箱");
				}

				if (this.form.address == '') {
					this.$message.error("地址不能为空");
					return false;
				}
				this.$post("index/companyInfoEdit", this.form).then((res) => {
					if (res) {
						this.$message.success('提交成功')
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			// 修改手机号
			modifyMobile() {
				this.dialogUser = true;
			},
			// 获取验证码
			getCode() {
				if (this.form1.mobile == "") {
					this.$message.error("请输入新手机号")
					return false;
				}

				if (!(/^1[3456789]\d{9}$/.test(this.form1.mobile))) {
					this.$message.error("手机号有误，请重新输入！")
					return false;
				}

				this.$post("index/userMobileVerify", {
					mobile: this.form1.mobile,
				}).then((res) => {
					if (res) {
						if (res.data == true) {
							this.$message.error("该手机号已注册")
							return false;
						}

						this.$post("http/sendMsg", {
							mobile: this.form1.mobile,
							smsToken: '55454654646565465',
						}).then((res) => {
							if (res) {
								const TIME_COUNT = 60;
								if (!this.timer) {
									this.count = TIME_COUNT;
									this.show = false;
									this.timer = setInterval(() => {
										if (this.count > 0 && this.count <= TIME_COUNT) {
											this.count--;
										} else {
											this.show = true;
											clearInterval(this.timer);
											this.timer = null;
										}
									}, 1000)
								}
							}
						}).catch((error) => {
							this.$message.error(error.message)
						})

					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
			// 点击修改手机号
			ensureClick() {
				if (this.form1.mobile == "") {
					this.$message.error("请输入新手机号")
					return false;
				}
				if (this.form1.code == "") {
					this.$message.error("验证码不能为空")
					return false;
				}

				this.$post("index/userModelEdit", {
					old_mobile: this.user.mobile,
					mobile: this.form1.mobile,
					code: this.form1.code,
				}).then((res) => {
					if (res) {
						this.form1 = {}
						this.sysTradeWay()
						this.$message.success('修改成功')
						this.dialogUser = false;
					}
				}).catch((error) => {
					this.$message.error(error.message)
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		width: 355px !important;
	}

	/deep/.el-input__wrapper {
		width: 355px !important;
	}

	/deep/.el-dialog__header {
		background: none;
		border-bottom: 1px solid #D6D9E2;

		.el-dialog__title {
			color: #000000;
		}

		.el-dialog__close {
			color: #999 !important;
		}
	}

	/deep/.el-dialog__footer {
		border-top: 1px solid #D6D9E2;
	}

	.roleCheck {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;

		.titleTxt {
			font-size: 24px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #252630;
			padding-bottom: 30px;
		}

		.left-title {
			width: 80px;
			text-align: right;
		}

		.btn1 {
			width: 120px;
			height: 40px;
			line-height: 40px;
			background: #3D6EE2;
			border-radius: 4px;
			font-size: 18px;
			color: #FFFFFF;
			text-align: center;
			margin-right: 20px;
			margin-left: 91px;
		}

		.btn2 {
			width: 120px;
			height: 40px;
			line-height: 40px;
			border: 1px solid #3D6EE2;
			border-radius: 4px;
			font-size: 18px;
			color: #3D6EE2;
			text-align: center;
			margin-right: 20px;
		}

		.phoneBox {
			.telBox {
				font-size: 16px;
				color: #252630;
			}

			.newTel {
				width: 470px;
				height: 40px;
				line-height: 40px;
				border: 1px solid #D6D9E2;
				border-radius: 4px;

				.icon_sjImg {
					width: 22px;
					height: 22px;
					margin-left: 14px;
					margin-right: 5px;
				}

				/deep/.el-input__wrapper {
					height: 36px;
					box-shadow: none;
				}

				.codeBox {
					position: absolute;
					left: 360px;
					font-size: 16px;
					color: #3D6EE2;
				}
			}
		}


	}
</style>